import React, {useState} from "react";
import {AuthenticationDetails, CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";
import {userPool} from './CognitoConfig';
import {navigate} from "gatsby";
import {isValidPassword} from "../../util/validators";
import RunicLogo from "../icon/RunicLogo";
import {GtagEvent, sendGtagEvent} from "./GtagEvent";

const { default: LogoPath } =
  require("../../images/navigation-icons/popstox.svg") as { default: string };

const UserSignIn = ({ redirect }: { redirect: string }) => {

  const [loginInfo, setLoginInfo] = useState<{
    username: string;
    password: string;
    error: string | undefined;
  }>({
    username: "",
    password: "",
    error: undefined
  })

  const [isFirstLogin, setIsFirstLogin] = useState<{
    isFirstLogin: boolean;
    user?: CognitoUser;
    userAttr: any;
    newPassword: string;
    repeatPassword: string;
    newPasswordError: string;
    repeatPasswordError: string;
    isPasswordError: boolean;
  }>({
    isFirstLogin: false,
    user: undefined,
    userAttr: undefined,
    newPassword: "",
    repeatPassword: "",
    newPasswordError: "",
    repeatPasswordError: "",
    isPasswordError: false
  })

  const { username, password, error } = loginInfo

  const onChangeNewPassword = (value) => {
    if (isFirstLogin.newPasswordError && isValidPassword(value)) {
      setIsFirstLogin({
        ...isFirstLogin,
        newPassword: value,
        newPasswordError: ""
      })
    } else {
      setIsFirstLogin({
        ...isFirstLogin,
        newPassword: value,
      })
    }
  }

  const onChangeRepeatPassword = (value) => {
    if (isFirstLogin.repeatPasswordError && value === isFirstLogin.newPassword) {
      setIsFirstLogin({
        ...isFirstLogin,
        repeatPassword: value,
        repeatPasswordError: ""
      })
    } else {
      setIsFirstLogin({
        ...isFirstLogin,
        repeatPassword: value,
      })
    }
  }

  const onChangePassword = (e) => {
    e.preventDefault()
    const isValidPassword = doValidateNewPassword()
    if (!isValidPassword) {
      setLoginInfo({ ...loginInfo, error: "please fix password errors" });
      return
    }
    isFirstLogin.user?.completeNewPasswordChallenge(isFirstLogin.newPassword, {}, {
      onSuccess(result: CognitoUserSession) {
        if (result) {
          navigate(redirect)
        }
      },
      onFailure(err) {
        const { message } = err;
        setLoginInfo({ ...loginInfo, error: message });
      }
    })
  }

  const onNewPasswordBlur = () => {
    const { newPassword } = isFirstLogin
    if (!isValidPassword(newPassword)) {
      setIsFirstLogin({
        ...isFirstLogin,
        newPasswordError: "Password invalid, please see requirements",
        isPasswordError: true
      })
    } else {
      if (isFirstLogin.newPasswordError) {
        setIsFirstLogin({
          ...isFirstLogin,
          newPasswordError: "",
          isPasswordError: false
        })
      }
    }
  }

  const doValidateNewPassword = () => {
    const { newPassword } = isFirstLogin
    let isValid = true
    if (!isValidPassword(newPassword)) {
      setIsFirstLogin({
        ...isFirstLogin,
        newPasswordError: "Password invalid, please see requirements",
        isPasswordError: true
      })
      isValid = false
    }
    if (!isRepeatPasswordValid()) {
      setIsFirstLogin({
        ...isFirstLogin,
        repeatPasswordError: "Repeat password must match new password",
        isPasswordError: true
      })
      isValid = false
    }
    return isValid
  }

  const isRepeatPasswordValid = () => {
    const { newPassword, repeatPassword } = isFirstLogin
    return newPassword === repeatPassword
  }

  const getRepeatPasswordError = () => {
    if (isFirstLogin.repeatPasswordError) {
      return (<div className={"text-red-700"}>{isFirstLogin.repeatPasswordError}</div>)
    } else {
      return null
    }
  }

  const getPasswordError = () => {
    if (isFirstLogin.newPasswordError) {
      return (<div className={"text-red-700"}>{isFirstLogin.newPasswordError}</div>)
    } else {
      return null
    }
  }

  const getFormError = () => {
    if (loginInfo.error) {
      return (<div className={"bg-red-100 border-red-300 text-red-700 p-2 text-sm text-center border rounded-md"}>{loginInfo.error}</div>)
    } else {
      return null
    }
  }

  const onChange = (id, value) => {
    setLoginInfo({
      ...loginInfo,
      [id]: value
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const authData = {
      Username: username,
      Password: password
    }
    const authDetails = new AuthenticationDetails(authData)
    const userData = {
      Username: username,
      Pool: userPool
    }
    const cognitoUser = new CognitoUser(userData)
    cognitoUser.authenticateUser(authDetails, {
      onSuccess(result: CognitoUserSession) {
        if (result) {
          sendGtagEvent(GtagEvent.LOGIN, {'redirect': redirect})
          navigate(redirect)
        }
      },
      newPasswordRequired(userAttributes, requiredAttributes) {
        setIsFirstLogin({
          isFirstLogin: true,
          userAttr: userAttributes,
          user: cognitoUser,
          newPassword: "",
          repeatPassword: "",
          isPasswordError: false,
          repeatPasswordError: "",
          newPasswordError: "",
        })
      },
      onFailure(err) {
        const { message } = err;
        setLoginInfo({ ...loginInfo, error: message });
      }
    });
  }

  const getForm = () => {
    if (isFirstLogin.isFirstLogin) {
      return (
        <form className="space-y-6" onSubmit={onChangePassword}>
          <div>
            The password you used was temporary, please enter a new password to update your account.
          </div>
          <div className={"text-sm"}>
            <b>Requirements:</b> <span className={"italic"}>Passwords must be 8 characters in length, have 1 number, 1 special character, 1 lowercase letter, and 1 uppercase letter.</span>
          </div>
          <div>
            {getFormError()}
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                disabled={true}
                value={username}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-400 bg-gray-100 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                value={isFirstLogin.newPassword || ""}
                onChange={(e) => onChangeNewPassword(e.target.value)}
                onBlur={(e) => { onNewPasswordBlur() }}
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
            <div>
              {getPasswordError()}
            </div>
          </div>
          <div>
            <label htmlFor="repeat-password" className="block text-sm font-medium text-gray-700">
              Repeat Password
            </label>
            <div className="mt-1">
              <input
                id="repeat-password"
                name="repeat-password"
                type="password"
                value={isFirstLogin.repeatPassword || ""}
                onChange={(e) => onChangeRepeatPassword(e.target.value)}
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
            <div>
              {getRepeatPasswordError()}
            </div>
          </div>
          <div className={"pt-4"}>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Update
            </button>
          </div>
        </form>
      )
    } else {
      return (
        <form className="space-y-6" onSubmit={onSubmit}>
          <div>
            <div>
              {getFormError()}
            </div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                value={username || ""}
                onChange={(e) => onChange("username", e.target.value)}
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                value={password || ""}
                onChange={(e) => onChange("password", e.target.value)}
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>

            <div className="text-sm text-right">
              <a href="/forgotpassword" className="font-medium text-blue-600 hover:text-blue-500">
                Forgot your password?
              </a>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Sign in
            </button>
          </div>
        </form>
      )
    }
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="w-full text-center font-bold text-xl">
          <RunicLogo class={"inline-block sr-only md:not-sr-only"} width={200} height={80} />
        </div>
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">sign in to your account</h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Or{' '}
          <a href="https://www.runic.ai" className="font-medium text-blue-600 hover:text-blue-500">
            request a trial
          </a>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {getForm()}
        </div>
      </div>
    </div>
  )
}

export default UserSignIn;
