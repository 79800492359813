export enum GtagEvent {
    VISIT_DASHBOARD = 'runic_VISIT_DASHBOARD',
    LOGIN = 'runic_LOGIN',
    FORGOT_PASSWORD = 'runic_FORGOT_PASSWORD',
    CHECK_GRAPH = 'runic_CHECK_GRAPH',
    CHECK_TOPICS = 'runic_CHECK_TOPICS',
    CHECK_INSIGHTS = 'runic_CHECK_INSIGHTS',
    CHECK_SOCIAL_FEED = 'runic_CHECK_SOCIAL_FEED',
    PAGE_VIEW = 'page_view',
}

export const sendGtagEvent = (eventName: GtagEvent, eventValues: {}) => {
    if (typeof window === 'undefined') return;
    if (window.gtag) {
        window.gtag('event', eventName, {
            ...eventValues,
        });
    }
}
